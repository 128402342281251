import React from 'react';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';

// Components
import { Wrapper, Inner, Title, Item } from './files';

const CarouselHome = ({ itemData }) => {
  const params = {
    slidesPerView: 2,
    spaceBetween: 15,
    autoplay: {
      delay: 3500
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 0
      }
    }
  };

  return (
    <Wrapper>
      <Inner>
        <Title>
          <h1>Upcoming Adelaide Events</h1>
          <h2>SEEING A SHOW? NEED A PLACE TO STAY?</h2>
        </Title>
        <Swiper {...params}>
          {itemData.map(({ node: item }) => (
            <Link
              className="carousel-item"
              key={item.id}
              to={`/events/${item.fields.seoContent.slug}/${item.fields.id}/`}
              title={item.fields.seoContent.pageTitle}
            >
              <Item item={item} />
            </Link>
          ))}
        </Swiper>
        <Title>
          <Link className="button" to="/events/" title="View all events">
            View all events
          </Link>
        </Title>
      </Inner>
    </Wrapper>
  );
};

export default CarouselHome;
