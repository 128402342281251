import styled from 'styled-components';

const Inner = styled.div`
  display: block;
  max-width: 1160px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  .swiper-container {
    position: initial;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgogIDxnPgogICAgPHBhdGggZD0ibTQwLjQsMTIxLjNjLTAuOCwwLjgtMS44LDEuMi0yLjksMS4ycy0yLjEtMC40LTIuOS0xLjJjLTEuNi0xLjYtMS42LTQuMiAwLTUuOGw1MS01MS01MS01MWMtMS42LTEuNi0xLjYtNC4yIDAtNS44IDEuNi0xLjYgNC4yLTEuNiA1LjgsMGw1My45LDUzLjljMS42LDEuNiAxLjYsNC4yIDAsNS44bC01My45LDUzLjl6IiBmaWxsPSIjMDAwMDAwIi8+CiAgPC9nPgo8L3N2Zz4K');
    width: 50px;
    height: 50px;
    background-size: 30px 30px;
    transition: all 300ms ease-in-out;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
    }
  }
  .swiper-button-next {
    right: 5%;
    left: auto;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      right: 3%;
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      right: 2%;
    }
  }
  .swiper-button-prev {
    transform: rotate(180deg);
    right: auto;
    left: 5%;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      left: 3%;
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      left: 2%;
    }
  }
  a {
    &.carousel-item {
      height: 560px;
      width: 100%;
      position: relative;
      overflow: hidden;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.medium}) {
        height: 424px;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.4)
        );
        transition: all 0.9s ease;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: #000;
        opacity: 0;
        transition: all 0.4s ease-in-out;
      }
      .gatsby-image-wrapper {
        height: 100%;
        img {
          transform: scale(1);
          transition: all 1s cubic-bezier(0.59, 0, 0.06, 1) 0s !important;
        }
      }
      &:hover {
        &::after {
          opacity: 0.6;
        }
        .gatsby-image-wrapper {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
`;

export default Inner;
