import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HomeHero from '../components/common/sections/homeHero';
import HomeGrid from '../components/common/sections/homeGrid';
import CarouselHome from '../components/common/sections/carouselHome';

const IndexPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;
  const filterEvents = data.allEventData.edges;
  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HomeHero contentSection={contentSections[1]} seoContent={seoContent} />
      <HomeGrid contentSection={contentSections[0]} />
      <CarouselHome itemData={filterEvents} />
    </>
  );
};

export const query = graphql`
  query {
    allEventData: allFile(
      filter: { fields: { localEvent: { eq: "true" } } }
      sort: { fields: fields___endDate, order: ASC }
      limit: 5
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          fields {
            id
            seoContent {
              menuTitle
              pageTitle
              slug
              description {
                description
              }
            }
            startDate
            endDate
          }
        }
      }
    }
    contentfulPages(id: { eq: "752dbf56-98d3-5ef6-aaea-b8e224657716" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        id
        title
        image {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imageGallery {
          file {
            url
            contentType
          }
        }
        link {
          id
          pageTitle
          menuTitle
          slug
          description {
            description
          }
          featuredImage {
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
