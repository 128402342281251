import React from 'react';

// Components
import { Wrapper, Image, Title } from './files';
import Video from './files/video';

const HomeHero = ({ seoContent, contentSection }) => {
  const { pageTitle, featuredImage } = seoContent;
  const { imageGallery } = contentSection;

  const hasVideo = imageGallery.length !== 0;

  return (
    <Wrapper>
      {hasVideo ? (
        <Video contentSection={contentSection} />
      ) : (
        <Image featuredImage={featuredImage} />
      )}
      <Title pageTitle={pageTitle} />
    </Wrapper>
  );
};

export default HomeHero;
