import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import moment from 'moment';

const Text = styled.div`
  position: absolute;
  bottom: 30px;
  padding: 20px;
  width: 100%;
  text-align: center;
  z-index: 99;
  h2 {
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.text.light};
    text-align: center;
    margin-bottom: 5px;
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.medium}) {
      font-size: 26px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: ${(props) => props.theme.colors.text.light};
    padding-left: 0;
    margin-bottom: 15px;
    list-style-type: disc;
    li {
      margin-left: 30px;
      display: list-item;
      text-align: -webkit-match-parent;
      &:first-child {
        list-style: none;
        margin-left: 0;
      }
      @media only screen and (max-width: ${(props) =>
          props.theme.responsive.xsmall}) {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          list-style: none;
          margin-left: 0;
        }
      }
    }
  }
  span {
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary} !important;
  }
`;

const Item = ({ item }) => (
  <>
    <Img
      fluid={item.childImageSharp.fluid}
      alt={item.fields.seoContent.menuTitle}
    />
    <Text>
      <h2>{item.fields.seoContent.menuTitle}</h2>
      <ul>
        <li>
          {moment(item.fields.startDate).format('MMMM Do YYYY')} at{' '}
          {moment(item.fields.startDate).format('h:mm a')}
        </li>
      </ul>
      <span>MORE DETAILS</span>
    </Text>
  </>
);

export default Item;
