import styled from 'styled-components';

const Wrapper = styled.section`
  width: 1366px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    width: auto;
    max-width: none;
    display: inline-flex;
  }
`;

export default Wrapper;
