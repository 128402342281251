import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import YouTube from 'react-youtube';

// Context
import ContextConsumer from '../../../contextProvider';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  > span {
    max-width: 1160px;
    width: 100%;
    padding: 32px;
    margin: 0 auto;
    h1 {
      max-width: 700px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: ${(props) => props.theme.colors.text.light};
      text-transform: uppercase;
    }
    button {
      font-size: 14px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: ${(props) => props.theme.colors.text.light};
      background: transparent;
      min-width: 220px;
      height: 52px;
      border-radius: 4px;
      border: solid 2px ${(props) => props.theme.colors.text.light};
      display: inline-block;
      text-align: center;
      @media only screen and (max-width: ${(props) =>
          props.theme.responsive.small}) {
        font-size: 12px;
        min-width: 180px;
        height: 40px;
        line-height: 38px;
      }
      &:hover {
        background: ${(props) => props.theme.colors.primary};
        border-color: ${(props) => props.theme.colors.primary};
      }
      img {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 10px;
        width: 20px;
        height: 20px;
        @media only screen and (max-width: ${(props) =>
            props.theme.responsive.small}) {
          width: 15px;
          height: 15px;
          left: 30px;
        }
      }
      span {
        margin-left: 30px;
      }
    }
  }
`;

const Button = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  height: 43px;
  width: 45px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover {
    img {
      &.close-icon {
        transform: rotate(180deg);
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    transition: all 300ms ease-in-out;
  }
`;

const Iframe = styled.div`
  position: relative;
  width: 640px;
  height: 360px;
  & > div {
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;

const ModalStyles = {
  overlay: {
    position: `fixed`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: `100%`,
    minWidth: `100%`,
    overflowY: `auto`,
    zIndex: `999`,
    background: `rgba(0,0,0,0.7)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    position: `fixed`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `transparent`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
    border: 'none'
  }
};

const opts = {
  playerVars: {
    autoplay: 1
  }
};

ReactModal.setAppElement('#___gatsby');

const Title = ({ pageTitle }) => (
  <ContextConsumer>
    {({ toggleVideo, showVideo }) => (
      <>
        <Wrapper>
          <span>
            <h1>{pageTitle}</h1>
            <button onClick={toggleVideo}>
              <img
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ5NC4xNDggNDk0LjE0OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDk0LjE0OCA0OTQuMTQ4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTQwNS4yODQsMjAxLjE4OEwxMzAuODA0LDEzLjI4QzExOC4xMjgsNC41OTYsMTA1LjM1NiwwLDk0Ljc0LDBDNzQuMjE2LDAsNjEuNTIsMTYuNDcyLDYxLjUyLDQ0LjA0NHY0MDYuMTI0ICAgIGMwLDI3LjU0LDEyLjY4LDQzLjk4LDMzLjE1Niw0My45OGMxMC42MzIsMCwyMy4yLTQuNiwzNS45MDQtMTMuMzA4bDI3NC42MDgtMTg3LjkwNGMxNy42Ni0xMi4xMDQsMjcuNDQtMjguMzkyLDI3LjQ0LTQ1Ljg4NCAgICBDNDMyLjYzMiwyMjkuNTcyLDQyMi45NjQsMjEzLjI4OCw0MDUuMjg0LDIwMS4xODh6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGRkZGRkYiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg=="
                alt="Watch Video"
              />
              <span>Watch Video</span>
            </button>
          </span>
        </Wrapper>
        <ReactModal
          isOpen={showVideo}
          contentLabel="Image Gallery"
          onRequestClose={toggleVideo}
          shouldCloseOnOverlayClick
          style={ModalStyles}
        >
          <Button onClick={toggleVideo}>
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgMzY1LjY5NiAzNjUuNjk2IiB3aWR0aD0iNTEyIiBjbGFzcz0iIj48Zz48cGF0aCBkPSJtMjQzLjE4NzUgMTgyLjg1OTM3NSAxMTMuMTMyODEyLTExMy4xMzI4MTNjMTIuNS0xMi41IDEyLjUtMzIuNzY1NjI0IDAtNDUuMjQ2MDkzbC0xNS4wODIwMzEtMTUuMDgyMDMxYy0xMi41MDM5MDYtMTIuNTAzOTA3LTMyLjc2OTUzMS0xMi41MDM5MDctNDUuMjUgMGwtMTEzLjEyODkwNiAxMTMuMTI4OTA2LTExMy4xMzI4MTMtMTEzLjE1MjM0NGMtMTIuNS0xMi41LTMyLjc2NTYyNC0xMi41LTQ1LjI0NjA5MyAwbC0xNS4xMDU0NjkgMTUuMDgyMDMxYy0xMi41IDEyLjUwMzkwNy0xMi41IDMyLjc2OTUzMSAwIDQ1LjI1bDExMy4xNTIzNDQgMTEzLjE1MjM0NC0xMTMuMTI4OTA2IDExMy4xMjg5MDZjLTEyLjUwMzkwNyAxMi41MDM5MDctMTIuNTAzOTA3IDMyLjc2OTUzMSAwIDQ1LjI1bDE1LjA4MjAzMSAxNS4wODIwMzFjMTIuNSAxMi41IDMyLjc2NTYyNSAxMi41IDQ1LjI0NjA5MyAwbDExMy4xMzI4MTMtMTEzLjEzMjgxMiAxMTMuMTI4OTA2IDExMy4xMzI4MTJjMTIuNTAzOTA3IDEyLjUgMzIuNzY5NTMxIDEyLjUgNDUuMjUgMGwxNS4wODIwMzEtMTUuMDgyMDMxYzEyLjUtMTIuNTAzOTA2IDEyLjUtMzIuNzY5NTMxIDAtNDUuMjV6bTAgMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="Close gallery"
              className="close-icon"
            />
          </Button>
          <Iframe>
            <YouTube videoId="kL86X_K75Aw" opts={opts} />
          </Iframe>
        </ReactModal>
      </>
    )}
  </ContextConsumer>
);

export default Title;
