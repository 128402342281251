import React from 'react';

// Components
import { Wrapper, Inner, Item } from './files';

const HomeGrid = ({ contentSection }) => (
  <Wrapper>
    <Inner>
      {contentSection.link.map(item => (
        <Item key={item.id} item={item} />
      ))}
    </Inner>
  </Wrapper>
);

export default HomeGrid;
