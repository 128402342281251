import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 60px);
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    background-image: linear-gradient(
      0,
      rgba(255, 255, 255, 0) 36%,
      rgba(0, 0, 0, 0.36) 100%
    );
  }
  @media (max-width: ${(props) => props.theme.responsive.small}) {
    height: 480px;
    &::before {
      background-image: linear-gradient(
        0,
        rgba(255, 255, 255, 0) 36%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }
  video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: calc(100vh - 60px);
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.small}) {
      height: 480px;
    }
  }
`;

const Video = ({ contentSection }) => (
  <Wrapper>
    <video
      poster={contentSection.image.fluid.srcWebp}
      id="background-video"
      playsInline
      loop
      muted
      autoPlay
    >
      {contentSection.imageGallery.map((i) => (
        <source key={i.file.url} src={i.file.url} type={i.file.contentType} />
      ))}
      Your browser does not support the video tag.
    </video>
  </Wrapper>
);

export default Video;
