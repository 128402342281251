import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 10;
  background: ${props => props.theme.colors.primary};
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
