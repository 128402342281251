import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Wrapper = styled(Link)`
  display: inline-block;
  position: relative;
  width: 33.33%;
  padding: 0 12px;
  margin-bottom: 15px;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 328px;
    padding: 0;
    margin-right: 16px;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  height: 560px;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    height: 424px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.5)
    );
    transition: all 0.9s ease;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: #000;
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    img {
      transform: scale(1);
      transition: all 1s cubic-bezier(0.59, 0, 0.06, 1) 0s !important;
    }
  }
  &:hover {
    &::after {
      opacity: 0.6;
    }
    .gatsby-image-wrapper {
      img {
        transform: scale(1.1);
      }
    }
  }
`;

const Text = styled.div`
  position: absolute;
  bottom: 30px;
  z-index: 2;
  text-align: center;
  padding: 32px 60px 40px;
  width: 100%;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding: 32px 40px;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: ${props => props.theme.colors.text.light};
    text-align: center;
    margin-bottom: 5px;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.medium}) {
      font-size: 26px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    min-height: 0;
    margin: 0;
    margin-bottom: 15px;
    color: ${props => props.theme.colors.text.light};
  }
  span {
    text-transform: uppercase;
    font-weight: 700;
    color: ${props => props.theme.colors.primary} !important;
  }
`;

const Item = ({ item }) => (
  <Wrapper to={`/${item.slug}/`} title={item.pageTitle}>
    <Inner>
      <Img fluid={item.featuredImage.fluid} alt={item.featuredImage.title} />
      <Text>
        <h2>{item.pageTitle}</h2>
        <span>VIEW {item.menuTitle}</span>
      </Text>
    </Inner>
  </Wrapper>
);

export default Item;
