import styled from 'styled-components';

const Title = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    margin-top: 60px !important;
    background: transparent !important;
    border-radius: 4px;
    border: solid 2px ${(props) => props.theme.colors.text.light};
    height: 52px !important;
    &:hover {
      background: ${(props) => props.theme.colors.background.dark} !important;
      border-color: ${(props) => props.theme.colors.background.dark};
    }
  }
  h1 {
    color: ${(props) => props.theme.colors.text.light};
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    padding-bottom: 10px;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.small}) {
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 20px;
    }
  }
  h2 {
    color: ${(props) => props.theme.colors.text.dark};
    opacity: 0.5;
    position: relative;
    font-size: 22px;
    line-height: 34px;
    padding-bottom: 20px;
    margin-bottom: 60px;
    text-transform: uppercase;
    text-align: center;
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.small}) {
      font-size: 18px;
      line-height: 26px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 270px;
      max-width: 50%;
      height: 1px;
      background: ${(props) => props.theme.colors.text.dark};
    }
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
`;

export default Title;
