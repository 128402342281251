import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const Wrapper = styled.div`
  .gatsby-image-wrapper {
    height: calc(100vh - 60px);
    overflow: hidden;
    @media only screen and (max-width: ${(props) =>
        props.theme.responsive.medium}) {
      height: 480px;
    }
  }
`;

const Image = ({ featuredImage }) => (
  <Wrapper>
    <Img fluid={featuredImage.fluid} alt={featuredImage.description} />
  </Wrapper>
);

export default Image;
