import styled from 'styled-components';

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 70px 32px;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
